<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          placeholder="type to search"
        ></v-text-field
      ></v-col>
    </v-row>

    <div v-if="mediations">
      <v-data-table :search="search" :items="mediations" :headers="headers">
        <template v-slot:[`item.exclusive_case_no`]="{ item }">
          <span class="">{{ item.exclusive_case_no }}</span>
        </template>

        <template v-slot:[`item.complainant`]="{ item }">
          <div v-if="item.complainant != null">
            <div v-for="item in JSON.parse(item.complainant)" :key="item.id">
              <div class="" style="">{{ item.complainant_name }}</div>
            </div>
          </div>
          <div v-else>
            <div>
              {{ item }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.respondent`]="{ item }">
          <div v-if="item.respondent != null">
            <div v-for="item in JSON.parse(item.respondent)" :key="item.id">
              <div class="" style="margin-top: 0px">
                <div class="" style="">{{ item.respondent_name }}</div>
              </div>
            </div>
          </div>
          <div v-else>
            <div>
              {{ item }}
            </div>
          </div>
        </template>

        <template v-slot:[`item.case_status`]="{ item }">
          <v-chip
            class="font-weight-bold black--text whiten-3"
            style="font-size: 8.5pt"
            dark
            color="yellow darken-1"
          >
            {{ item.case_status.toUpperCase() }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <v-col class="mt-1">
              <v-icon @click="view(item)" color="success" size="20"
                >mdi-eye-arrow-right
              </v-icon>

              <span
                class="font-weight-bold"
                style="color: green; font-size: 7pt"
                >VIEW</span
              >
            </v-col>
            <v-col class="mt-1">
              <v-icon color="deep-purple accent-4" size="20" @click="edit(item)"
                >mdi-pencil-outline
              </v-icon>
              <span
                class="font-weight-bold"
                style="color: #311b92; font-size: 7pt"
                >EDIT</span
              >
            </v-col>
            <v-col class="mt-1">
              <v-icon color="red" size="20" @click="confirm_del(item)"
                >mdi-delete
              </v-icon>
              <span class="font-weight-bold" style="color: red; font-size: 7pt"
                >DELETE</span
              >
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.period_of_hearing`]="{ item }">
          <div>
            <v-row>
              <!-- check if there is no entry in tables eg mediation table
              // if not checked, it will cause error and will not display rows in the table
              -->
              <v-col v-if="item.mediation">
                {{ format_date(item.mediation.date_of_first_hearing) }}
                -
                {{ format_date(item.mediation.date_of_last_hearing) }}</v-col
              >
              <v-col v-else>
                <p class="red--text">
                  No data found. Please delete and try again
                </p>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>
    </div>
    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>

<script>
import ProgressLinear from "@/components/ProgressLinear.vue";
// import EditComplaint from "../../views/EditComplaint.vue"
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    ProgressLinear,
    // EditComplaint
  },
  data() {
    return {
      search: "",
      date_settled_dialog: false,
      agreement: "",
      current_date: moment(new Date()).format("YYYY-MM-DD"),
      add_lupon_dialog_width: 400,
      dialog_cols: 6,
      conciliation_or_arbitration: "",
      dialog_heading: "",
      barangay_captain: [],
      judges: [],
      lupon_members: new Array(),
      change_status_item: "",
      dialog: false,
      dialog2: false,
      progress_dialog: false,
      default_status: "Ongoing",
      change_status: "",
      status: [
        "Withdrawn",
        "For Conciliation",
        "For Arbitration",
        "Unsettled",
        "Settled",
        "Dismissed",
      ],

      headers: [
        {
          text: "Docket No.",
          value: "exclusive_case_no",
          width: "120px",
        },
        {
          text: "Case Title",
          value: "case_title",
          class: " ",
          width: "300px",
        },
        {
          text: "Complainant/s",
          value: "complainant",
          class: " ",
        },
        {
          text: "Respondent/s",
          value: "respondent",
          class: "",
          // class: "grey ",
        },
        {
          text: "Period of Hearing",
          value: "period_of_hearing",
          class: "",
          // class: "grey ",
        },
        {
          text: "Case Status",
          value: "case_status",
          class: " ",
          // class: "grey ",

          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          class: "",
          align: "center",
          width: "155px",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      // mediations: "complaint/mediations",
      complaints: "complaint/complaints",
      lupons: "lupon/lupons",
      user: "auth/user",
      complaint: "complaint/complaint",
    }),
    mediations() {
      if (this.complaints == null) return [];
      else {
        let filtered = this.complaints.filter((m) => {
          return m.current_table == "Mediation";
        });
        return filtered;
      }
    },
  },
  methods: {
    ...mapActions({
      set_complaint: "complaint/set_complaint",
      set_edit_item: "complaint/set_edit_item",
      get_lupons: "lupon/get_lupons",
      set_notification: "notification/set_notification",
      delete_complaint: "complaint/delete_complaint",
    }),
    confirm_del(item) {
      let confirm = window.confirm("Are you sure you want to proceed?");
      if (confirm) {
        this.delete_complaint(item).then(() => {
          alert("Successfuly deleted");
        });
      }
    },
    //view complaint profile
    async view(item) {
      this.progress_dialog = true;
      item.table_name = "mediation";
      await this.set_complaint(item);
      this.$router.push("/complaintprofile/" + item.exclusive_case_no);
      this.progress_dialog = false;
    },

    async edit(item) {
      let obj = {
        arbitration: item.arbitration,
        case_status: item.case_status,
        case_title: item.case_title,
        case_type: item.case_type,
        complainant: item.complainant,
        conciliation: item.conciliation,
        created_at: item.created_at,
        current_table: item.current_table,
        exclusive_case_no: item.exclusive_case_no,
        id: item.id,
        mediation: item.mediation,
        referred_to_concerned_agency: item.referred_to_concerned_agency,
        reliefs_sought: item.reliefs_sought,
        respondent: item.respondent,
        settled: item.settled,
        statement_of_facts: item.statement_of_facts,
        table_route_history: item.table_route_history,
        unsettled: item.unsettled,
        table_name: "mediation",
      };
      console.log(obj);
      await this.set_edit_item(obj);
      this.$router.push("/editcomplaint/" + item.exclusive_case_no);
    },
    //open dialog for changing status
    open_dialog(item) {
      this.change_status_item = item;
      this.dialog = true;
    },
    format_date(item) {
      console.log(item);
      var x = moment(item).format("MMM D, YYYY");
      return x;
    },
  },
  created() {},
};
</script>
<style scoped>
.v-data-table ::v-deep td {
  font-size: 12px !important;
}
/* .v-data-table-header th {
  white-space: nowrap;
  text-align: center;
} */
</style>
